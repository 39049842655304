<template>
  <div id="faqCopm">
    <div class="page-header">
      <div class="container-fluid">
        <h2 class="h5 no-margin-bottom">Центр загрузок</h2>
      </div>
    </div>

    <div class="container-fluid">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#" @click="$router.push('/faq')">Faq</a>
        </li>
        <li class="breadcrumb-item active">Центр загрузок</li>
      </ul>
    </div>

    <div class="container">
      <div class="row">
        <div class="card">
          <div class="card-body text-left">
            <img src="img/launcher.png" class="d-inline-flex" />
            <div class="d-inline-flex"></div>
          </div>
        </div>
        <div class="card">
          <div class="card-body text-left">
            <p class="mb-4 h5 text-primary">
              Для установки софта вы должны выполнить следующие действия:
            </p>
            <p class="h6">1. Отключите антивирус, защитник Windows</p>
            <p class="h6">2. Установите Visual C++ 2017 x86/x64</p>
            <p class="h6">
              3. Скачайте лаунчер и распакуйте в удобное для вас место
            </p>
            <p class="h6">4. Запустите D2JSR.exe от имени администратора</p>

            <div class="d-flex justify-content-center mt-3">
              <button type="button" class="btn btn-primary mr-5">
                Launcher
              </button>
              <button type="button" class="btn btn-secondary mr-5">
                Visual C++
              </button>
            </div>

            <div class="d-inline-flex"></div>
          </div>
        </div>
      </div>
    </div>
    <!--
            <i class="fa fa-chevron-left"></i>
            <i class="fa fa-chevron-down"></i>
        -->

    <!--
            style="box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, .1);"
        -->

    <div class="page-header">
      <div class="container-fluid">
        <h2 class="h5 no-margin-bottom">Часто задаваемые вопросы</h2>
      </div>
    </div>

    <!--<section class="no-padding-top no-padding-bottom">
            <div class="container-fluid">
                <div class="accordion" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button class="btn btn-link text-primary" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Разворачиваемая панель #1 <i class="fa fa-chevron-down"></i>
                                </button>
                            </h5>

                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-primary" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Разворачиваемая панель #2
                                            </button>
                                        </h5>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-primary" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Разворачиваемая панель #3
                                            </button>
                                        </h5>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
  </div>
</template>

<script>
export default {};
</script>
