<template>
  <div id="headComp">
    <header class="header">
      <nav class="navbar navbar-expand-lg">
        <div
          class="container-fluid d-flex align-items-center justify-content-between"
        >
          <div class="navbar-header">
            <a href="#" class="navbar-brand" @click="$router.push('/')">
              <div class="brand-text brand-big visible text-uppercase">
                <strong style="color: #CDCED0;">D</strong>
                <strong style="color: #E33B3B;">2</strong>
                <strong style="color: #CDCED0;">JS:</strong>
                <strong style="color: #CDCED0;">Reload</strong>
              </div>
              <div class="brand-text brand-sm">
                <strong style="color: #CDCED0;">D</strong>
                <strong style="color: #E33B3B;">2</strong>
                <strong style="color: #CDCED0;">JS:</strong>
                <strong style="color: #CDCED0;">R</strong>
              </div>
            </a>
            <button
              class="sidebar-toggle need-hide-menu-button"
              :class="{ active: showSidebar }"
              type="button"
              @click="toggleSidebar"
            >
              <i class="fa fa-bars"></i>
            </button>
          </div>
          <div class="text-center" v-if="!userInfo.activated.active">
            <strong style="color: white">
              Вы ещё не подтвердили email. Не получили письмо?
              <a href="#" class="text-primary" @click="resend">Повторить?</a>
            </strong>
          </div>

          <ul class="right-menu list-inline no-margin-bottom">
            <!--<li class="list-inline-item dropdown"><a id="navbarDropdownMenuLink1" href="http://example.com" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link messages-toggle"><i class="fa fa-newspaper-o"></i><span class="badge dashbg-1">5</span></a>
                            <ul aria-labelledby="navbarDropdownMenuLink1" class="dropdown-menu messages">
                                <li>
                                    <a href="#" class="dropdown-item message d-flex align-items-center">
                                        <div class="profile"><img src="img/logo.jpg" alt="..." class="img-fluid">
                                            <div class="status online"></div>
                                        </div>
                                        <div class="content"> <strong class="d-block">Nadia Halsey</strong><span class="d-block">lorem ipsum dolor sit amit</span><small class="date d-block">9:30am</small></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item message d-flex align-items-center">
                                        <div class="profile"><img src="img/logo.jpg" alt="..." class="img-fluid">
                                            <div class="status away"></div>
                                        </div>
                                        <div class="content"> <strong class="d-block">Peter Ramsy</strong><span class="d-block">lorem ipsum dolor sit amit</span><small class="date d-block">7:40am</small></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item message d-flex align-items-center">
                                        <div class="profile"><img src="img/logo.jpg" alt="..." class="img-fluid">
                                            <div class="status busy"></div>
                                        </div>
                                        <div class="content"> <strong class="d-block">Sam Kaheil</strong><span class="d-block">lorem ipsum dolor sit amit</span><small class="date d-block">6:55am</small></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item message d-flex align-items-center">
                                        <div class="profile"><img src="img/logo.jpg" alt="..." class="img-fluid">
                                            <div class="status offline"></div>
                                        </div>
                                        <div class="content"> <strong class="d-block">Sara Wood</strong><span class="d-block">lorem ipsum dolor sit amit</span><small class="date d-block">10:30pm</small></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item text-center message"> <strong>See All Messages <i class="fa fa-angle-right"></i></strong></a>
                                </li>
                            </ul>
            </li>-->

            <li class="list-inline-item logout">
              <a id="logout" href="#" class="nav-link" @click="log_out">
                Выйти ({{ userInfo.email }})
                <i class="icon-logout"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'headComp',
  computed: {
    ...mapGetters(['userInfo', 'showSidebar'])
  },
  data() {
    return {
      active_button: false
    };
  },
  methods: {
    ...mapActions(['logout', 'resend_email', 'active_hide', 'toggleSidebar']),
    async log_out() {
      await this.logout();
      this.$router.push('/auth');
    },
    async resend() {
      let response = await this.resend_email();
      if (response.status === 'success') {
        Messenger().post({
          message: 'Повторное письмо отправлено!',
          type: 'success'
        });
      } else {
        if (response.time) {
          Messenger().post({
            message:
              'Вы сможете отправить повторный email через ' +
              (((response.time / 1000) >> 0) + '') +
              ' секунд!',
            type: 'error',
            hideAfter: 1.8
          });
          return;
        }
        Messenger().post({
          message: 'Произошла ошибка!',
          type: 'error'
        });
      }
    }
  }
};
</script>
