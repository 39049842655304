<template>
  <div id="faqCopm">
    <div class="page-header">
      <div class="container-fluid">
        <h2 class="h5 no-margin-bottom">Центр загрузок</h2>
      </div>
    </div>

    <!--<div class="container-fluid">
            <ul class="breadcrumb">
                <li class="breadcrumb-item"><a href="#" @click="$router.push('/faq')">Faq</a></li>
                <li class="breadcrumb-item active">Центр загрузок </li>
            </ul>
        </div>-->

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2" />
        <div class="col-lg-8 ">
          <div class="card">
            <div class="card-body text-center">
              <img src="img/launcher.png" class="d-inline-flex" />
            </div>
          </div>
          <div class="card">
            <div class="card-body text-left">
              <p class="mb-4 h5 text-primary">
                Для установки софта вы должны выполнить следующие действия:
              </p>
              <p class="h6">1. Отключите антивирус, защитник Windows</p>
              <p class="h6">2. Установите Visual C++ 2017 x86/x64</p>
              <p class="h6">
                3. Скачайте лаунчер и распакуйте в удобное для вас место
              </p>
              <p class="h6">4. Запустите D2JSR.exe от имени администратора</p>
              <p class="h5 text-primary mt-4">
                99. Если возникли какие то проблемы, обратитесь в техническую
                поддержку
              </p>

              <div class="d-flex justify-content-center mt-3">
                <a
                  href="https://cloud.mail.ru/public/4JTp/387gDGvxG"
                  target="_blank"
                  ><button type="button" class="btn btn-primary mr-5">
                    Launcher
                  </button></a
                >
                <a
                  href="https://support.microsoft.com/ru-ru/help/2977003/the-latest-supported-visual-c-downloads"
                  target="_blank"
                  ><button type="button" class="btn btn-secondary">
                    Visual C++
                  </button></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
