<template>
  <div class="auth__wrapper">
    <div class="auth__main">
      <authComp v-if="$route.name === 'auth'" />
      <regComp v-if="$route.name === 'register'" />
      <recComp v-if="$route.name === 'recover'" />
    </div>
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<style lang="sass">
.auth
  &__wrapper
    display: flex
    height: 100vh
    width: 100%
    overflow: hidden
    background: linear-gradient(to bottom, #202020, #111119)
  &__main
    border-radius: 10px
    background-color: #0e0e0e
    padding: 40px 40px 40px
    text-align: center
    display: flex
    flex-direction: column
    margin: auto
    z-index: 1
.form-group
  text-align: left
.circles
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden


.circles li
    position: absolute
    display: block
    list-style: none
    width: 20px
    height: 20px
    background: rgba(255, 255, 255, 0.2)
    animation: animate 25s linear infinite
    bottom: -150px


.circles li:nth-child(1)
    left: 25%
    width: 80px
    height: 80px
    animation-delay: 0s

.circles li:nth-child(2)
    left: 10%
    width: 20px
    height: 20px
    animation-delay: 2s
    animation-duration: 12s


.circles li:nth-child(3)
    left: 70%
    width: 20px
    height: 20px
    animation-delay: 4s

.circles li:nth-child(4)
    left: 40%
    width: 60px
    height: 60px
    animation-delay: 0s
    animation-duration: 18s


.circles li:nth-child(5)
    left: 65%
    width: 20px
    height: 20px
    animation-delay: 0s


.circles li:nth-child(6)
    left: 75%
    width: 110px
    height: 110px
    animation-delay: 3s


.circles li:nth-child(7)
    left: 35%
    width: 150px
    height: 150px
    animation-delay: 7s


.circles li:nth-child(8)
    left: 50%
    width: 25px
    height: 25px
    animation-delay: 15s
    animation-duration: 45s


.circles li:nth-child(9)
    left: 20%
    width: 15px
    height: 15px
    animation-delay: 2s
    animation-duration: 35s


.circles li:nth-child(10)
    left: 85%
    width: 150px
    height: 150px
    animation-delay: 0s
    animation-duration: 11s

@keyframes animate
  0%
    transform: translateY(0) rotate(0deg)
    opacity: 1
    border-radius: 0

  100%
    transform: translateY(-1000px) rotate(720deg)
    opacity: 0
    border-radius: 50%
</style>

<script>
import authComp from '@/components/authComponent.vue';
import regComp from '@/components/regComponent.vue';
import recComp from '@/components/recoverComponent.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'auth',
  components: {
    authComp,
    regComp,
    recComp
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  /* data(){
        return {
            login: "",
            password: "",
            rePassword: "",
            logining: false
        }
    },
    methods: {
        ...mapActions(['login_func']),
        async on_login() {
            if (this.login == "" || this.password == "") return
            this.logining = true
            let response = await this.login_func({"login": this.login, "password": this.password})

            if (response == null ||response.status === "error") {
                Messenger().post({
                    message: "Ошибка авторизации!",
                    type: "error"
                })
                this.password = ""
                this.logining = false
                return
            }
            if (response.status === "success") {
                Messenger().post({
                    message: "Успешная авторизация, переадресация!",
                    type: "success",
                    hideAfter: 0.5
                })
                setTimeout(() => {
                    this.$router.push('/')
                    this.logining = false
                }, 500)
                return
            }
        },
        reset_button() {
            this.logining = false
        }
	},*/
  methods: {
    ...mapActions(['checkAuth'])
  },
  async mounted() {
    const statusData = await this.checkAuth()
    if (statusData !== 'no_login') {
      await this.$router.push('/');
    }
  }
};
</script>
