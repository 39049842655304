<template>
  <div class="profileComp">
    <div
      style="
        width: 100%;
        height: 100%;
        background-color: #34373d;
        position: absolute;
        z-index: 99;
        opacity: 0.85;
      "
      v-if="!userInfo.activated.active"
    ></div>
    <div class="page-header">
      <div class="container-fluid">
        <h2 class="h5 no-margin-bottom">Профиль</h2>
      </div>
    </div>

    <section class="no-padding-top no-padding-bottom">
      <div class="mainbox">
        <div class="col-lg-6 col-xl-8">
          <div class="card card-profile">
            <div class="card-header"></div>
            <div class="card-body text-center">
              <img src="img/logo.jpg" class="card-profile-img" />
              <h4 class="mb-3 text-gray-light">{{ userInfo.login }}</h4>
              <p class="mb-2">
                <strong>{{ sub_level }}</strong>
              </p>
              <label class="font-weight-light h7" style="font-size: 8"
                >Осталось:
                <strong class="text-primary">
                  {{ expiried_count.count }}
                </strong>
                {{ expiried_count.text }}
                <label v-if="expiried_count.hours != null"
                  >и
                  <strong class="text-primary">
                    {{ expiried_count.hours }}
                  </strong>
                  {{ expiried_count.hours_text }}
                </label>
              </label>
              <div class="d-flex justify-content-center mt-3">
                <div class="pay-box d-flex flex-column">
                  <!-- <button
                    type="button"
                    class="btn btn-primary"
                    @click="$router.push('/shop')"
                  >
                    Купить
                  </button> -->
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="collapse"
                    data-target="#collapseShop"
                    aria-expanded="true"
                    aria-controls="collapseShop"
                  >
                    Купить
                  </button>
                  <div
                    id="collapseShop"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="buy__box">
                      <div class="buy__info">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td>Выберите кол-во дней:</td>
                              <td style="text-align: right">
                                <vue-numeric-input
                                  v-model="days_count"
                                  :min="1"
                                  :max="360"
                                  :step="1"
                                  autofocus
                                  class="buy__input"
                                ></vue-numeric-input>
                              </td>
                            </tr>
                            <tr>
                              <td>Скидка за опт:</td>
                              <td style="text-align: right">
                                {{ discount_wholesale || 0 }}
                              </td>
                            </tr>
                            <tr>
                              <td>Скидка за оплату более чем на месяц:</td>
                              <td style="text-align: right">
                                {{ discount_more_mounth || 0 }}
                              </td>
                            </tr>
                            <tr>
                              <td>Финальная цена:</td>
                              <td style="text-align: right; color: #379392">
                                {{ final_price || 0 }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="goPayYandex"
                      >
                        Оплата картой (yoomoney)
                      </button>

                      <!-- <button
                        type="button"
                        class="btn btn-secondary"
                        @click="payQiwi"
                      >
                        Qiwi
                      </button> -->
                      <hr />
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="$router.push('/shop')"
                      >
                        Oplata.info [webmoney, btc, ltc etc]
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="$router.push('/downloads')"
                  >
                    Центр загрузок
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------- -->
        <div class="col-lg-6 col-xl-8">
          <div class="card">
            <div class="card-header">Связать учётные записи</div>
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 100%; height: 100%; background-color: #34373d"
              v-if="userInfo.steam.linking"
            >
              <div class="card-body d-flex justify-content-center">
                <div class="d-flex justify-content-center mt-4">
                  <div class="sk-three-bounce">
                    <div class="sk-child sk-bounce1"></div>
                    <div class="sk-child sk-bounce2"></div>
                    <div class="sk-child sk-bounce3"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="linked-account"
              v-if="userInfo.steam.full && !userInfo.steam.linking"
            >
              <div class="steam-login">
                <img
                  :src="userInfo.steam.avatar"
                  alt="..."
                  class="steam-avatar"
                />
                <div class="d-flex justify-content-center mt-3">
                  <label>{{ userInfo.steam.nick }}</label>
                </div>
                <div class="d-flex justify-content-center">
                  <label class="text-primary" style="font-size: 14px"
                    ><a target="_blank" :href="steam_id_link">{{
                      userInfo.steam.id
                    }}</a></label
                  >
                </div>
              </div>
              <hr class="m-4" />
              <div class="linked-button">
                <div class="d-flex justify-content-center mb-2">
                  <button
                    class="btn btn-outline-secondary"
                    :disabled="!can_unlink"
                    @click="un_link"
                  >
                    <span class="fa fa-steam"></span> Отвязать
                  </button>
                </div>
                <div
                  class="text-center d-flex flex-column"
                  style="font-size: 12px"
                  v-if="need_unlink_msg"
                >
                  <span>
                    Использовано сбросов
                    <strong class="text-primary">{{
                      userInfo.details.steam_unlink.num_unlink
                    }}</strong>
                    из
                    <strong class="text-primary">{{
                      userInfo.details.steam_unlink.count_unlink
                    }}</strong
                    >.&nbsp;
                  </span>

                  <label v-if="unlink_extime"
                    >Обнуление попыток сброса:
                    <strong
                      class="text-primary"
                      data-toggle="tooltip"
                      :title="unlink_extime_time"
                      >{{ unlink_extime_time }}</strong
                    >.</label
                  >
                </div>
              </div>
            </div>
            <div
              class="linked-account"
              v-if="!userInfo.steam.full && !userInfo.steam.linking"
            >
              <div class="steam-login d-flex justify-content-center">
                <label
                  class="text-primary"
                  style="font-size: 16px; margin: 16px"
                  >Для использования продукта, привяжите Steam</label
                >
              </div>
              <div class="linked-button ml-auto p-2">
                <button class="btn btn-outline-secondary" @click="link">
                  <span class="fa fa-steam"></span> Привязать
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------- -->
        <div class="col-lg-6 col-xl-8">
          <div class="card">
            <div class="card-header">Активация промокода</div>
            <div class="card-body">
              <div class="promocode-box">
                <input
                  type="text"
                  placeholder="Ввести промокод"
                  class="form-control d-flex justify-content-center"
                  v-model="promocode"
                />
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-outline-secondary"
                    @click="on_promo"
                    :disabled="promo_process"
                  >
                    Активировать промокод
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------- -->
        <div class="col-lg-6 col-xl-8">
          <div class="card">
            <div class="card-header">Настройка аккаунта</div>

            <div class="card-body">
              <div class="form-group row">
                <label class="col-sm-3 form-control-label">Пароль </label>
                <div class="col-sm-9">
                  <div class="input-group bootstrap-touchspin-injected">
                    <input
                      type="text"
                      name="password"
                      class="form-control"
                      value="*********"
                      disabled
                    />
                    <span class="input-group-btn input-group-append">
                      <button
                        class="btn btn-secondary bootstrap-touchspin-up"
                        data-toggle="modal"
                        data-target="#passModal"
                        type="button"
                      >
                        Изменить
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal-->
      <changeEmail />
      <changePass />
    </section>
  </div>
</template>

<style lang="sass">
.mainbox
  display: flex
  justify-content: top
  align-items: center
  flex-direction: column
.linked-account
  height: 100%
.linked-button
  display: flex
  flex-direction: column
.promocode-box
  display: flex
  flex-wrap: wrap
  flex-direction: column
  justify-content: space-between
  align-items: center
  input
    max-width: 80%
    margin: 12px 0

.pay-box
  button
    margin: 4px 0
.buy
  &__box
    display: flex
    flex-direction: column
    margin: 16px
    span
      text-align: left
  &__input
    width: 100% !important
    input
      height: calc(2.0rem + 2px)
      border: 1px solid #444951 !important
      background: transparent
      border-radius: 0
      color: #979a9f
      padding: .45rem .75rem
    button
      background-color: #868e96 !important
      cursor: pointer !important
      i
        color: white !important
  &__info
    text-align: left
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import changeEmail from '@/components/modals/changeEmail.vue';
import changePass from '@/components/modals/changePass.vue';
import { clearInterval, setInterval } from 'timers';
import VueNumericInput from 'vue-numeric-input';

const types = [
  ['День', 'Дня', 'Дней'],
  ['Час', 'Часа', 'Часов'],
  ['Минута', 'Минуты', 'Минут'],
];

function declOfNum(number, titles) {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

function convertToText(num, n_type) {
  return declOfNum(num, types[n_type]);
}
const options = {
  cost: 21.6,
  oneDayPrice: 59,
  weeklyDiscount: 9.3,
  wholesaleMinDays: 7, //Минимальное количество дней, когда считать скидку за опт
  wholesaleDiscount: 1.2,
  wholesaleMaxDays: 35,
  renewalDiscountEnabled: true,
  renewalMinDays: 29,
  renewalDiscount: 9.3,
  renewalCount: 3,
  minBuyDays: 1,
  maxBuyDays: 365,
};

const GetSubscriptionPrice = (_days) => {
  let count = Math.floor(_days);
  let actual_price = count * options.cost;
  if (isNaN(actual_price)) actual_price = 0;
  let discount = 0;
  let renewal_discount = 0;
  if (count < options.minBuyDays || count > options.maxBuyDays) return null;

  if (count < options.wholesaleMinDays) {
    actual_price = options.oneDayPrice * count;
    let weekly_discount =
      (actual_price / 100) *
      (options.wholesaleMinDays - (options.wholesaleMinDays - (count - 1))) *
      options.weeklyDiscount;
    actual_price -= weekly_discount;
  }

  if (count > options.wholesaleMinDays) {
    discount =
      (actual_price / 100) *
      options.wholesaleDiscount *
      Math.min(options.wholesaleMaxDays, count);
    actual_price -= discount;
  }

  if (options.renewalDiscountEnabled && count > options.renewalMinDays) {
    renewal_discount =
      (actual_price / 100) * options.renewalDiscount * options.renewalCount;
    actual_price -= renewal_discount;
  }

  return {
    discount_wholesale: discount.toFixed(2),
    discount_more_mounth: renewal_discount.toFixed(2),
    final_price: actual_price.toFixed(2),
  };
};
export default {
  name: 'profileComp',
  computed: {
    ...mapGetters(['userInfo', 'sub_level']),
    discount_wholesale() {
      return GetSubscriptionPrice(this.days_count).discount_wholesale;
    },
    discount_more_mounth() {
      return GetSubscriptionPrice(this.days_count).discount_more_mounth;
    },
    final_price() {
      return GetSubscriptionPrice(this.days_count).final_price;
    },
    need_unlink_msg() {
      if (this.userInfo.sub_level == 0 || this.userInfo.sub_level > 3)
        return false;
      if (
        this.userInfo.sub_level > 0 &&
        this.userInfo.details.expired_time < Date.now()
      )
        return false;
      return true;
    },
    unlink_extime() {
      let extime =
        this.userInfo.details.steam_unlink.last_steam_unlink +
        60 * 60 * 24 * 7 * 1000;
      if (extime < Date.now()) return null;
      if (extime > Date.now()) {
        let time = new Date(extime);
        return time.toLocaleDateString();
      }
    },
    unlink_extime_time() {
      let extime =
        this.userInfo.details.steam_unlink.last_steam_unlink +
        60 * 60 * 24 * 7 * 1000;
      if (extime < Date.now()) return null;
      if (extime > Date.now()) {
        let time = new Date(extime);
        return time.toLocaleDateString() + ' ' + time.toLocaleTimeString();
      }
    },
    can_unlink() {
      if (this.userInfo.sub_level == 0 || this.userInfo.sub_level > 3)
        return true;
      let extime =
        this.userInfo.details.steam_unlink.last_steam_unlink +
        60 * 60 * 24 * 7 * 1000;
      if (extime < Date.now()) return true;
      if (
        this.userInfo.details.steam_unlink.num_unlink >=
        this.userInfo.details.steam_unlink.count_unlink
      )
        return false;

      return true;
    },
    steam_id_link: function () {
      return 'https://steamcommunity.com/profiles/' + this.userInfo.steam.id;
    },
    getRegDate() {
      let time = new Date(this.userInfo.details.reg_time);
      return time.toLocaleDateString();
    },
    getExpireDate() {
      let message = '';
      switch (this.userInfo.sub_level) {
        case 0:
        case 4:
        case 5: {
          message = '∞';
          break;
        }
        default:
          {
            let time = new Date(
              this.userInfo.details.expired_time
                ? this.userInfo.details.expired_time
                : 0
            );
            message = time.toLocaleString();
          }
          break;
      }
      return message;
    },
    getLastTime() {
      if (this.userInfo.details.last_launch == 0) {
        return 'Никогда';
      } else {
        return new Date(this.userInfo.details.last_launch).toLocaleString();
      }
    },
    getIP() {
      return this.userInfo.details.reg_ip;
    },
  },
  components: {
    changeEmail,
    changePass,
    VueNumericInput,
  },
  data() {
    return {
      Messenger: null,
      expiried_count: { count: 0, text: 'дней' },
      allow_unlink: { allow: false, date: '01.01.1970' },
      promocode: '',
      promo_process: false,
      recaptchaToken: null,
      days_count: 30,
      pay_type: 'cards',
    };
  },
  methods: {
    ...mapActions([
      'getUserInfo',
      'updateSteam',
      'activate_promo',
      'payYandex',
    ]),
    payQiwi() {},
    goPayYandex() {
      if (this.days_count)
        window.open(
          `/api/start_payment/${this.pay_type}/yandex/${this.days_count}`,
          '_blank'
        );
    },
    async un_link() {
      this.userInfo.steam.full = false;
      let response = await this.updateSteam('unlink');
      if (response.status == 'error' && response.error_code == 'no_login') {
        this.$router.push('/auth');
      }
    },
    link() {
      let linkWindow = window.open(
        '/api/steam/auth',
        'linker',
        'location,width=640,height=480,top=10'
      );
      linkWindow.focus();
      const timer = setInterval(() => {
        if (linkWindow.closed) {
          clearInterval(timer);
          this.updateSteam('link');
        }
      }, 200);
    },
    async promo_activate() {
      let response = await this.activate_promo({
        code: this.promocode,
        'g-recaptcha-response': this.recaptchaToken,
      });

      this.promo_process = false;
      this.promocode = '';
      if (response.status == 'success') {
        this.getUserInfo();
        return Messenger().post({
          message: 'Успешная активация кода!',
          type: 'success',
        });
      }

      if (response.status == 'error') {
        switch (response.error_code) {
          case 'no_login':
            return $router.push('/auth');
          case 'no_activate':
            return Messenger().post({
              message: 'Ошибка, подтвердите email!',
              type: 'error',
            });
          case 'bad_data':
            return Messenger().post({
              message: 'Промокод не найден, или уже был активирован!',
              type: 'error',
            });
          case 'no_captcha':
            return Messenger().post({
              message: 'Ошибка решения капчи, попробуйте снова!',
              type: 'error',
            });
          case 'already_activate':
            return Messenger().post({
              message: 'Промокод уже был активирован на вашем аккаунте!',
              type: 'error',
            });
          case 'code_expiried':
            return Messenger().post({
              message: 'Промокод неактуален!',
              type: 'error',
            });
          default:
            return Messenger().post({
              message: 'Неизвестная ошибка!',
              type: 'error',
            });
        }
      }
    },
    on_promo() {
      if (this.promocode == '')
        return Messenger().post({
          message: 'Введите промокод!',
          type: 'error',
        });
      this.promo_process = true;

      this.promo_activate();
    },
    countExpiried() {
      switch (this.userInfo.sub_level) {
        case 0:
        case 4:
        case 5: {
          this.expiried_count = { count: '∞', text: 'Дней' };
          return;
        }
        default:
          {
            let delta =
              ((this.userInfo.details.expired_time - Date.now()) / 1000) >> 0;
            if (delta > 0) {
              if (delta > 86400) {
                let time = delta / 86400;
                let hours = parseInt((time - parseInt(time)) * 24);
                time = time >> 0;
                this.expiried_count = {
                  count: time,
                  text: convertToText(time, 0),
                  hours: hours,
                  hours_text: convertToText(hours, 1),
                };
                return;
              } else if (delta < 3600) {
                let time = (delta / 60) >> 0;
                this.expiried_count = {
                  count: time,
                  text: convertToText(time, 2),
                };
                return;
              } else {
                let time = (delta / 3600) >> 0;
                this.expiried_count = {
                  count: time,
                  text: convertToText(time, 1),
                };
                return;
              }
            } else {
              this.userInfo.sub_level = 0;
              this.expiried_count = { count: 0, text: 'Дней' };
              return;
            }
          }
          break;
      }
      this.expiried_count = { count: '??', text: 'дней' };
    },
  },
  mounted() {
    setInterval(() => {
      this.countExpiried();
    }, 100);
  },
};
</script>
