import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import sharedMutations from 'vuex-shared-mutations';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

axios.defaults.withCredentials = true;

function base_user() {
  return {
    loaded: false,
    update: false,
    login: null,
    email: null,
    sub_level: 0,
    unlink: {
      1: 3,
      2: 9,
      3: 15,
    },
    steam: {
      full: false,
      id: null,
      avatar: null,
      nick: null,
      linking: false,
    },
    details: {
      expired_time: null,
      reg_time: null,
      last_launch: null,
      reg_ip: null,
    },
    activated: {
      active: false,
    },
    hide: true,
  };
}
const service_url = [
  'https://www.digiseller.market/asp2/pay_wm.asp?id_d=',
  'https://plati.market/itm/',
][0];
export default new Vuex.Store({
  state: {
    user: base_user(),
    update: false,
    global: {
      shop: {
        2: {
          //месяц
          price: 299,
          old_price: 0,
          discount: 0,
          link: `${service_url}2667796`,
        },
        3: {
          //3 месяца
          price: 799,
          old_price: 0,
          discount: 0,
          link: `${service_url}2667799`,
        },
        4: {
          //6 месяцев
          price: 1599,
          old_price: 0,
          discount: 0,
          link: `${service_url}2667800`,
        },
        0: {
          //1 день
          price: 59,
          old_price: 0,
          discount: 0,
          link: `${service_url}2804567`,
        },
        1: {
          //1 неделя
          price: 149,
          old_price: 0,
          discount: 0,
          link: `${service_url}2804568`,
        },
      },
    },
    showSidebar: false,
  },
  getters: {
    userInfo: (state) => state.user,
    state: (state) => state,
    showSidebar: (state) => state.showSidebar,
    global: (state) => state.global,
    sub_level: (state) => {
      let result = '';
      switch (state.user.sub_level) {
        case 0:
          result = 'Нет подписки [nonSub]';
          break;
        case 1:
          result = 'Пользователь [PRO]';
          break;
        case 2:
          result = 'Пользователь [PRO+]';
          break;
        case 3:
          result = 'Пользователь [VIP]';
          break;
        case 4:
          result = "YOUTUBE'р";
          break;
        case 5:
          result = 'Admin';
          break;
      }
      return result;
    },
  },
  mutations: {
    update(state) {
      state.update = true;
    },
    logout_mutation(state) {
      state.user = base_user();
    },
    set_preload(state) {
      state.user.loaded = false;
    },
    hide(state) {
      state.user.hide = !state.user.hide;
    },
    toggle(state) {
      state.showSidebar = !state.showSidebar;
    },
  },
  actions: {
    getUserInfo: ({ state }) => {
      return axios.get('/api/getInfo').then((response) => {
        if (response.data.status === 'success') {
          for (let i in response.data) {
            state.user[i] = response.data[i];
          }
        }
        return response.data;
      });
    },
    checkAuth: async ({ state, dispatch }) => {
      if (!state.user.loaded) {
        let response = await dispatch('getUserInfo', { state });
        if (response.status === 'success') {
          state.user.loaded = true;
        } else {
          if (response.status === 'error') {
            if (response.error_code === 'no_login') {
              return 'no_login';
            }
          }
        }
      }
    },
    unlinkSteam: async () => {
      return axios.get('/api/steam/unlink').then((response) => {
        return response.data;
      });
    },
    resend_email: async () => {
      return axios.get('/api/activate/resend').then((response) => {
        return response.data;
      });
    },
    updateSteam: async ({ state, dispatch }, mode) => {
      state.user.steam.linking = true;
      if (mode === 'unlink') {
        await dispatch('unlinkSteam');
      }

      let response = await dispatch('getUserInfo');
      state.user.steam.linking = false;
      return response;
    },
    logout: ({ commit }) => {
      commit('logout_mutation');
      return axios.get('/api/logout').then((response) => {
        return response.data;
      });
    },
    login_func: (ctx, logindata) => {
      return axios.post('/api/login', logindata).then((response) => {
        return response.data;
      });
    },
    reg_func: (ctx, regdata) => {
      return axios.post('/api/register', regdata).then((response) => {
        return response.data;
      });
    },
    rec_func: (ctx, recdata) => {
      return axios.post('/api/recover', recdata).then((response) => {
        return response.data;
      });
    },
    init_daemon: async ({ state, dispatch }) => {
      if (window.update_daemon == null) {
        window.update_daemon = setInterval(() => {
          if (state.update) {
            dispatch('getUserInfo');
            state.update = false;
          }
        }, 200);
      }
    },
    reset_base: ({ commit }) => {
      commit('logout_mutation');
    },
    change_password: async (ctx, data) => {
      return axios.post('/api/change_password', data).then((response) => {
        return response.data;
      });
    },
    activate_promo: async (ctx, data) => {
      return axios.post('/api/activatePromo', data).then((response) => {
        return response.data;
      });
    },
    active_hide: ({ commit }) => {
      commit('hide');
    },
    toggleSidebar: ({ commit }) => {
      commit('toggle');
    },
  },
  plugins: [sharedMutations({ predicate: ['update'] })],
});
