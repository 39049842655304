import ruLocale from 'vee-validate/dist/locale/ru';
import Vue from 'vue';
/* import VModal from 'vue-js-modal';
 */
import App from './App.vue';
import router from './router';
import store from './store';

import VeeValidate from 'vee-validate';

VeeValidate.Validator.localize('ru', ruLocale);
Vue.use(VeeValidate);



Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
