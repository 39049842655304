<template>
  <div id="recoverComp">
    <div class="text-center">
      <img
        src="img/logo.png"
        alt="..."
        style="max-width: 18rem;"
        class="img-fluid mb-4"
      />
      <h3 class="text-gray-light mb-4">Восстановление пароля</h3>
    </div>

    <div class="form-group" style="margin: 15% 0">
      <label>Введите Email</label>
      <input
        name="email"
        v-validate="'required|min:3|max:64|email'"
        type="text"
        placeholder="name@domain.ru"
        autocomplete="off"
        required
        data-msg="Пожалуйста введите ваш  email"
        class="form-control"
        v-model.trim="email"
      />
      <div
        class="d-flex flex-row align-self-center mt-2"
        v-if="errors.has('email')"
      >
        <i v-show="errors.has('email')" class="fa fa-warning error-icon"></i>
        <span v-show="errors.has('email')" class="help is-danger error-text"
          >&nbsp; {{ errors.first('email') }}</span
        >
      </div>
    </div>

    <!-- Submit-->
    <button
      type="button"
      class="btn btn-lg btn-block btn-primary mb-3"
      @click="on_wait_restore"
      :disabled="recovered"
    >
      Восстановить
    </button>
    <!-- Link-->
    <p class="text-center">
      <small class="text-muted text-center"
        >Вспомнили?
        <a href="#" @click="$router.push('/auth')">Авторизоваться</a>.</small
      >
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      recaptchaToken: null,
      email: '',
      recovered: false
    };
  },
  methods: {
    ...mapActions(['rec_func']),
    async recover_on() {
      let response = await this.rec_func({
        email: this.email,
        'g-recaptcha-response': this.recaptchaToken
      });

      this.recovered = false;

      if (response.status == 'success') {
        this.$router.push('/');
        return Messenger().post({
          message: 'Сообщение с дальшейней информацией отправлено на email!',
          type: 'success',
          hideAfter: 0.8
        });
      }

      if (response.status == 'error') {
        if (response.time) {
          return Messenger().post({
            message:
              'Вы сможете запросить восстановление аккаунта на этот email через ' +
              (((response.time / 1000) >> 0) + '') +
              ' секунд!',
            type: 'error',
            hideAfter: 1.8
          });
        }
        switch (response.error_code) {
          case 'no_captcha': {
            return Messenger().post({
              message: 'Капча разгадана неверно, попробуйте снова!',
              type: 'error',
              hideAfter: 1.8
            });
          }
          case 'no_account': {
            return Messenger().post({
              message: 'Аккаунта не существует!',
              type: 'error',
              hideAfter: 1.8
            });
          }
          case 'no_connect': {
            return Messenger().post({
              message: 'Ошибка соединения с базой данных!',
              type: 'error',
              hideAfter: 1.8
            });
          }
        }
      }

      return Messenger().post({
        message: 'Неизвестная ошибка!',
        type: 'error',
        hideAfter: 0.8
      });
    },
    on_wait_restore() {
      if (this.email == '' || this.errors.has('email'))
        return Messenger().post({
          message: 'Проверьте правильность заполнения!',
          type: 'error',
          hideAfter: 0.8
        });

      this.recovered = true;

      this.recover_on();
    }
  }
};
</script>
