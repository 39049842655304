<template>
  <div
    id="update"
    style="background-color: #2d3035; height: 100vh; padding: 10% 0 0 0;"
  >
    <div class="container">
      <h1 class="text-center">
        Email адрес успешно подтвержден, вернитесь на
        <a href="#" @click="$router.push('/')"><strong>главную</strong></a
        >.
      </h1>
    </div>
  </div>
</template>

<script>
import { setTimeout } from 'timers';

export default {
  name: 'update',
  mounted() {
    setTimeout(() => {
      this.$store.commit('update');
    }, 1000);
  }
};
</script>
